import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {
    isBusyLoading: true,
    domainInfo: null,
    domainList: [],
  },
  getters: {},
  mutations: {
    UPDATE_BUSY_LOADING_STATE(state, val) {
      state.isBusyLoading = val
    },
    UPDATE_DOMAIN_INFO_STATE(state, val) {
      state.domainInfo = val
    },
    UPDATE_DOMAIN_LIST_STATE(state, val) {
      state.domainList = val
    },
  },
  actions: {
    // (查詢)網域服務列表
    getDomainList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/domainName', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (查詢)服務列表
    getDomainService(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/domainName/service', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (查詢)服務
    getDomainServiceInfo(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/domainName/service/${resolveData.url}`, { headers: { 'Access-Token': useJwt.getToken() }, params: resolveData.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (新增)網域服務
    setDomainServiceAdd(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/domainName/service', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (編輯)網域服務
    setDomainServiceUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/domainName/${resolveData.url}`, {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // --------------------------------手動觸發串接--------------------------------------
    // (網域)
    setOrderServiceDomain(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/domainName/${resolveData.domain_name_id}/action`, {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (查詢)網域詳情
    // getDomainData(ctx, resolveData) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get(`/admin/domainName/service/${resolveData.url}`, { headers: { 'Access-Token': useJwt.getToken() }, params: resolveData.queryParams })
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
  },
}
