<template>
  <div>
    <validation-observer
      ref="domainEditForm"
      #default="{invalid}"
    >
      <b-modal
        id="edit-modal"
        ref="edit-modal"
        no-close-on-backdrop
        ok-title="確認"
        centered
        header-bg-variant="primary"
        ok-only
        @hidden="handleHide"
        @close="handleHide"
        @ok="handleOk"
      >
        <template #modal-title>
          <h4 class="m-0 model-header">
            編輯服務
          </h4>
        </template>

        <div>
          <b-form
            @reset.prevent="resetForm"
            @submit.prevent="handleOk"
          >
            <!-- 狀態 -->
            <div
              v-if="domainData.state === 5 || domainData.state === 1 || domainData.state === 6"
              class="mb-1"
            >
              <b-form-group
                label="狀態"
                label-for="state"
              >
                <v-select
                  v-model="domainData.state"
                  :options="[{
                    label: '未啟用', value: 5,
                  }, {
                    label: '啟用', value: 1,
                  }, {
                    label: '待處理', value: 6,
                  }]"
                  class="w-100"
                  :reduce="option => option.value"
                  :clearable="false"
                >
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      沒有<em>{{ search }}</em> 的相關結果
                    </template>

                    <div v-else>
                      <small>暫無資料</small>
                    </div>
                  </template>

                  <template v-slot:option="option">
                    <div class="d-flex align-items-center">
                      <span
                        v-if="option.value === 1"
                        class="mr-50 state-dot dot-green-selected"
                      />
                      <span
                        v-else-if="option.value === 6"
                        class="mr-50 state-dot dot-blue-selected"
                      />
                      <span
                        v-else-if="option.value === 5"
                        class="mr-50 state-dot dot-gray"
                      />
                      {{ option.label }}
                    </div>
                  </template>

                  <template #selected-option="option">
                    <div class="d-flex align-items-center">
                      <span
                        v-if="option.value === 1"
                        class="mr-50 state-dot dot-green-selected"
                      />
                      <span
                        v-else-if="option.value === 6"
                        class="mr-50 state-dot dot-blue-selected"
                      />
                      <span
                        v-else-if="option.value === 5"
                        class="mr-50 state-dot dot-gray"
                      />
                      {{ option.label }}
                    </div>
                  </template>
                </v-select>
              </b-form-group>
            </div>

            <!-- 會員 -->
            <div class="mb-1">
              <b-form-group
                label="會員"
                label-for="customer"
              >
                <div
                  class="d-flex justify-content-start align-items-center cursor-pointer border p-1"
                  @click="submitCustomerChoice"
                >
                  <div class="mr-1">
                    <b-avatar
                      size="40"
                      :src="domainData.customer_info.image"
                    />
                  </div>
                  <div
                    v-if="domainData.customer_info.id"
                    class="profile-user-info"
                  >
                    <h6 class="mb-0">
                      <span class="mr-50">{{ domainData.customer_info.name }}{{ domainData.customer_info.family_name }}</span>
                    </h6>

                    <small
                      class="text-muted"
                      style="word-break: break-all"
                    > {{ domainData.customer_info.account }}</small>
                  </div>

                  <div v-else>
                    <h6 class="mb-0">
                      <span class="mr-50">新增會員</span>
                    </h6>
                  </div>
                </div>
              </b-form-group>
            </div>

            <!-- <hr class="my-2"> -->

            <!-- 備註 -->
            <!-- <div>
              <div class="font-weight-bold mb-1 d-flex justify-content-between w-100 align-items-center">
                <h5 class="mb-0">
                  備註
                </h5>

                <div v-if="editCol === 'content'">
                  <b-img
                    :src="require('@/assets/images/pages/ui/blue-line/checked-blue.svg')"
                    width="18"
                    class="mr-75 cursor-pointer"
                    @click="confirmEditCol"
                  />

                  <b-img
                    :src="require('@/assets/images/pages/ui/blue-line/close-blue.svg')"
                    width="18"
                    class="cursor-pointer"
                    @click="cancelEditCol"
                  />
                </div>

                <div v-else>
                  <div
                    class="actions-link-btn mr-25"
                    @click="selectEditCol('content')"
                  >
                    <b-img
                      v-b-tooltip.hover.focus.v-secondary
                      title="編輯"
                      src="/dashboard/admin/images/table/edit.svg"
                      class="actions-link-btn-image"
                      rounded
                    />
                  </div>
                </div>
              </div>

              <div v-if="editCol === 'content'">
                <b-form-textarea
                  v-model="editColData"
                  placeholder="請輸入備註"
                  no-resize
                  :state="domainData.content.length <= maxChar ? null : false"
                  rows="5"
                />
                <small
                  class="textarea-counter-value float-right"
                  :class="editColData.length > maxChar ? 'bg-danger' : ''"
                >
                  <span class="char-count">{{ editColData.length }}</span> / {{ maxChar }}
                </small>
              </div>

              <div v-else>
                <span
                  v-if="domainData.content"
                  class="font-weight-bold"
                >
                  {{ domainData.content }}
                </span>

                <span
                  v-else
                  class="text-muted"
                >
                  該服務無任何備註
                </span>
              </div>
            </div> -->
          </b-form>
        </div>

        <template #modal-footer>
          <div class="w-100 text-right">
            <b-button
              variant="primary"
              :disabled="invalid || isBusy || editCol"
              @click="handleOk"
            >
              <span v-if="!isBusy">確認</span>

              <b-spinner
                v-else
                small
              />
            </b-button>
          </div>
        </template>
      </b-modal>
    </validation-observer>

    <admin-customer-modal
      ref="adminCustomerEditExplorerModal"
      branch-explorer-id="adminCustomerEditExplorerModal"
      :is-hiden-emit="false"
      :use-callback-data="true"
      @emit-hidden-function="() => {}"
      @call-back-data="callbacCustomerExplorer"
    />
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BModal, BForm, VBTooltip, BButton, BSpinner, BAvatar, BFormGroup, // BFormTextarea,  BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

// VeeValidate
import { required } from '@validations'
import { ValidationObserver } from 'vee-validate'

// Component
import { useSwalToast, useAlert } from '@/libs/mixins/index'
import AdminCustomerModal from '@/layouts/components/Modal/admin-customer-modal/customerModal.vue'
import { useDomainServiceList, useDomainSetting } from '../useDomain'

export default {
  components: {
    // BImg,
    BForm,
    BModal,
    BAvatar,
    BButton,
    BSpinner,
    BFormGroup,
    // BFormTextarea,
    ValidationObserver,
    vSelect,
    AdminCustomerModal,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  data() {
    return {
      missingFields: [],
      maxChar: 255,
      required,
      selected: null,
      editCol: null,
      editColData: null,
      isTableBusy: false,
    }
  },
  methods: {
    // (選取)編輯欄位
    selectEditCol(type) {
      this.editCol = type
      this.editColData = JSON.parse(JSON.stringify(this.domainData[type]))
    },

    // (取消)編輯欄位
    cancelEditCol() {
      this.editCol = null
    },

    // (確認)編輯欄位
    confirmEditCol() {
      this.domainData[this.editCol] = this.editColData
      this.editCol = null
    },

    // 按下彈窗關閉
    handleHide(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.resetDomainData()
      this.isBusy = false
      this.$nextTick(() => {
        this.$bvModal.hide('edit-modal')
      })
    },

    // 按下彈窗確認
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()

      if (this.isBusy) return

      const requiredFields = {
        customer: '會員',
      }

      this.missingFields = []

      for (let i = 0; i < Object.keys(requiredFields).length; i += 1) {
        if (this.domainData[Object.keys(requiredFields)[i]] === null || this.domainData[Object.keys(requiredFields)[i]] === '') {
          this.missingFields.push({
            label: Object.values(requiredFields)[i],
            value: Object.keys(requiredFields)[i],
          })
        }
      }

      if (this.missingFields.length > 0) {
        this.useAlertToast(false, `${this.missingFields[0].label}不得為空`)
        return
      }
      const resolveData = {
        url: this.domainData.url,
        data: {
          content: this.domainData.content,
          customer: this.domainData.customer,
          state: this.domainData.state,
        },
      }

      this.isBusy = true
      this.$refs.domainEditForm.validate()
        .then(success => {
          if (success) {
            this.setDomainServiceUpdate(resolveData)
              .then(response => {
                this.useAlertToast(response.data.success, response.data.message)
                this.isBusy = false
                this.$emit('refetch-data')
                this.resetDomainData()
                this.$nextTick(() => {
                  this.$bvModal.hide('edit-modal')
                })
              })
              .catch(error => {
                this.useHttpCodeAlert(error.response)
                this.isBusy = false
              })
          } else {
            this.useAlertToast(false, '資料填寫不完全')
            this.isBusy = false
          }
        })
    },

    // (觸發)會員選擇
    submitCustomerChoice() {
      this.$refs.adminCustomerEditExplorerModal.getData(this.domainData.customer_info)
    },

    // (回傳)所選擇的會員資料
    callbacCustomerExplorer(item) {
      this.domainData.customer_info.id = item.id
      this.domainData.customer_info.ssid = item.ssid
      this.domainData.customer_info.name = item.name
      this.domainData.customer_info.family_name = item.family_name
      this.domainData.customer_info.account = item.account
      this.domainData.customer_info.image = item.image
      this.domainData.customer = item.ssid
    },

    // (開啟)編輯服務
    getData(item) {
      this.$bvModal.show('edit-modal')
      const resolveData = {
        ...this.syncObject(this.blankDomainData, item),
      }
      resolveData.customer_info = this.syncObject({
        id: null,
        ssid: null,
        name: null,
        family_name: null,
        account: null,
        image: null,
      }, item.customer_info)

      this.domainData = JSON.parse(JSON.stringify(resolveData))
    },
  },
  setup() {
    const {
      syncObject,
      // getMetaListData,
    } = useDomainSetting()

    const {
      isBusy,
      setDomainServiceUpdate,
    } = useDomainServiceList()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const domainData = ref(null)

    const blankDomainData = {
      id: null,
      url: null,
      customer: null,
      state: 1,
      content: '',
      customer_info: {
        id: null,
        ssid: null,
        name: null,
        family_name: null,
        account: null,
        image: null,
      },
    }

    domainData.value = JSON.parse(JSON.stringify(blankDomainData))

    const resetDomainData = () => {
      domainData.value = JSON.parse(JSON.stringify(blankDomainData))
    }

    return {
      isBusy,
      useAlertToast,
      useHttpCodeAlert,
      domainData,
      blankDomainData,
      setDomainServiceUpdate,
      resetDomainData,

      syncObject,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}

.dot-green-selected {
  background:radial-gradient(circle closest-side at center,#66ff66,#1bb425);
}

.dot-yellow-selected {
  background:radial-gradient(circle closest-side at center,#f6e9ad,#f6bc01);
}

.dot-red-selected {
  background:radial-gradient(circle closest-side at center,#f6adad,#f32929);
}

.dot-blue-selected {
  background:radial-gradient(circle closest-side at center,#9ecfdf,#65d8ff);
}

// primary
</style>
